import { Component, Input } from '@angular/core';
import { APP_ROUTE } from '@core/constants';

@Component({
	selector: 'findqo-sale-properties-submitted',
	templateUrl: './sale-properties-submitted.component.html',
	styleUrl: './sale-properties-submitted.component.scss',
})
export class SalePropertiesSubmittedComponent {
	@Input() userEmail: string;
	readonly appRoute = APP_ROUTE;
}
