import { Component, Input } from '@angular/core';
import { APP_ROUTE } from '@core/constants';

@Component({
	selector: 'findqo-rent-properties-submitted',
	templateUrl: './rent-properties-submitted.component.html',
	styleUrl: './rent-properties-submitted.component.scss',
})
export class RentPropertiesSubmittedComponent {
	@Input() userEmail: string;
	readonly appRoute = APP_ROUTE;
}
